<template>
    <div class="wrapper">
        <div style="margin-bottom: 30px;">
            <div style="font-size: 24px;font-weight: bold;color: #000;display: flex;justify-content: center;">
                Distributor Application Form
            </div>
            <div style="margin-top: 17px;">
                <div style="display: flex;justify-content: center;">We are pleased that your company is interested in
                    becoming our distributor in your market. </div>
                <div style="display: flex;justify-content: center;">The below Application Form is designed to provide us
                    with valuable information about your company, of which we</div>
                <div style="display: flex;justify-content: center;">are hopeful and poised at the potential for a long-term
                    business relationship.</div>
            </div>
        </div>
        <div>
            <el-steps :active="active" finish-status="success" align-center>
                <el-step title="Background Infomation"></el-step>
                <el-step title="Requested Distribution Territory"></el-step>
                <el-step title="Your Channels Of Distribution"></el-step>
                <el-step title="Marketing Plan"></el-step>
            </el-steps>
        </div>
        <el-card style="padding: 10px 10px 50px;margin-top: 50px;">
            <div v-if="currentStep === 1">
                <div @nextStep="nextStep">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" label-position="top"
                        class="demo-ruleForm">
                        <div class="title">
                            <p class="titles">Background Infomation</p>
                        </div>
                        <div style="margin-top: 30px;">
                            <div style="display: flex;">
                                <el-form-item label="Company name" prop="name" style="flex: 1;">
                                    <el-input v-model="ruleForm.name" placeholder="Enter Company name"></el-input>
                                </el-form-item>
                                <el-form-item label="Company TAX ID" prop="taxid" style="flex: 1;margin-left: 30px;">
                                    <el-input v-model="ruleForm.taxid" placeholder="Enter Company Tax ID"></el-input>
                                </el-form-item>
                            </div>
                            <div style="display: flex;">
                                <el-form-item label="Contact person" prop="person" style="flex: 1;">
                                    <el-input v-model="ruleForm.person" placeholder="Enter Contact Person"></el-input>
                                </el-form-item>
                                <el-form-item label="Title" prop="title" style="flex: 1;margin-left: 30px;">
                                    <el-input v-model="ruleForm.title" placeholder="Enter Title"></el-input>
                                </el-form-item>
                            </div>
                            <div style="display: flex;">
                                <el-form-item label="E-Mail" prop="email" style="flex: 1;">
                                    <el-input v-model="ruleForm.email" placeholder="Enter Email"></el-input>
                                </el-form-item>
                                <el-form-item label="Phone" prop="phone" style="flex: 1;margin-left: 30px;">
                                    <el-input v-model="ruleForm.phone" placeholder="Enter Phone Number"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item label="Company address" prop="add">
                                <el-input v-model="ruleForm.add" placeholder="Enter Company Address"></el-input>
                            </el-form-item>
                            <el-form-item label="Website" prop="web">
                                <el-input v-model="ruleForm.web" placeholder="Enter Website"></el-input>
                            </el-form-item>
                        </div>
                        <el-form-item label="Number of Sales Representatives" prop="repNum">
                            <el-input v-model="ruleForm.repNum"
                                placeholder="Enter Your Number of Sales Repressentatives"></el-input>
                        </el-form-item>
                        <el-form-item label="Trade Shows/Exhibits attended ( Please list the name here )" prop="SHandEX">
                            <el-input v-model="ruleForm.SHandEX"
                                placeholder="Enter trade shows or exhibition you attended"></el-input>
                        </el-form-item>
                        <div style="float: right;">
                            <el-button type="primary" plain @click="submitForm('ruleForm')"
                                style="width: 151px;">Next</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
            <div v-else-if="currentStep === 2">
                <div @nextStep="nextStep" @prevStep="prevStep">
                    <el-form :model="form1" label-position="top" label-width="auto">
                        <div class="title">
                            <p class="titles">Requested Distribution Territory</p>
                        </div>
                        <div style="margin-top: 30px;">
                            <el-form-item label="Country" prop="country">
                                <el-input v-model="form1.country" placeholder="Enter Your Country"></el-input>
                            </el-form-item>
                            <el-form-item label="Region" prop="region">
                                <el-input v-model="form1.region" placeholder="Enter Your Region"></el-input>
                            </el-form-item>
                            <el-form-item label="city" prop="city">
                                <el-input v-model="form1.city" placeholder="Enter Your Major City"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
            <div v-else-if="currentStep === 3">
                <div @nextStep="nextStep" @prevStep="prevStep">
                    <el-form label-position="top" :model="form2" label-width="auto">
                        <div class="title">
                            <p class="titles">Your Channels Of Distribution</p>
                        </div>
                        <div style="margin-top: 30px;">
                            <div>Please tick from below channels listed you are involved</div>
                            <div style="margin-top: 10px;">
                                <div class="tit">Have your own store</div>
                                <div style="margin: 10px 0 20px;">
                                    <el-form-item label="Online store:" prop="store1">
                                        <el-input v-model="form2.store1"
                                            placeholder="put your online website here"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Off-Line Store:" prop="store2">
                                        <el-input v-model="form2.store2"
                                            placeholder="put your online website here"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="tit">Sell to Wholesaler</div>
                                <div style="margin: 10px 0 20px;">
                                    <el-form-item label="Wholesaler Customer qty:" prop="WCqty">
                                        <el-input v-model="form2.WCqty"
                                            placeholder="put your wholesaler customer qty here"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="tit">Sell to Retailer</div>
                                <div style="margin: 10px 0 20px;">
                                    <el-form-item label="Supermarkets, specialty pet stores:" prop="ssps">
                                        <el-input v-model="form2.ssps"
                                            placeholder="please specify how many such stores you sell to, and list main store name. like Walmart, Petsmart, etc"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Clinics, grooming store, or independent stores:" prop="cgis">
                                        <el-input v-model="form2.cgis"
                                            placeholder="please specify how many such stores you sell to, and list main store name if possible"></el-input>
                                    </el-form-item>
                                </div>
                                <div class="tit">Others</div>
                                <div style="margin: 10px 0 20px;">
                                    <el-form-item label="other info:" prop="info">
                                        <el-input v-model="form2.info" placeholder="put your other info here"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Current Product Lines and brands Distributed?" prop="distributed">
                                        <el-input v-model="form2.distributed"></el-input>
                                    </el-form-item>
                                    <el-form-item
                                        label="Do you sell in any third party portal, or have plan to sell through third party portals?"
                                        prop="portals">
                                        <el-input v-model="form2.portals"
                                            placeholder="The list is such as Amazon, Sears, Facebook, Walmart, Ebay, etc, but not limited to these."></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div v-else-if="currentStep === 4">
                <div @nextStep="nextStep" @prevStep="prevStep">
                    <el-form label-position="top" :model="form3" label-width="auto">
                        <div class="title">
                            <p class="titles">MARKETING PLAN</p>
                        </div>
                        <div style="margin-top: 30px;">
                            <el-form-item
                                label="Do you have any marketing plan and estimated sales volume for our brands in your market? Please list your plan below. Thank you."
                                prop="plan">
                                <el-input type="textarea" v-model="form3.plan" :rows="10"
                                    placeholder="Please enter your marketing plan"></el-input>
                            </el-form-item>
                        </div>
                        <div style="float: right;">
                            <el-button type="primary" plain @click="submit" style="width: 151px;">Submit</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
            <div>
                <div v-if="currentStep !== 1" style="float: left;">
                    <el-button type="primary" plain @click="prevStep" style="width: 231px;">Previous Step</el-button>
                </div>
                <div v-if="currentStep !== 4 && currentStep !== 1" style="float: right;">
                    <el-button type="primary" plain @click="nextStep" style="width: 151px;">Next</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            active: 0,
            currentStep: 1,
            ruleForm: {
                name: '',
                taxid: '',
                add: '',
                web: '',
                person: '',
                title: '',
                email: '',
                phone: '',
                repNum: '',
                SHandEX: '',
            },
            form1: {
                country: '',
                region: '',
                city: '',
            },
            form2: {
                store1: '',
                store2: '',
                WCqty: '',
                ssps: '',
                cgis: '',
                info: '',
                distributed: '',
                portals: '',
            },
            form3: {
                plan: '',
            },
            rules: {
                name: [
                    { required: true, message: 'please enter your name', trigger: 'blur' },
                ],
                add: [
                    { required: true, message: 'please enter your address', trigger: 'blur' },
                ],
                person: [
                    { required: true, message: 'please enter your Contact person', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'please enter your Email', trigger: 'blur' },
                    { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
                ],
                title: [
                    { required: true, message: 'please enter your Title', trigger: 'blur' },
                ],
            },
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    this.$message.error('Please confirm that you have filled it out completely and then click Next');
                } else {
                    window, scrollTo(0, 0)
                    this.active++
                    if (this.currentStep < 4) {
                        this.currentStep += 1;
                    }
                }
            });
        },
        nextStep() {
            this.active++
            if (this.currentStep < 4) {
                this.currentStep += 1;
            }
            window, scrollTo(0, 0)
        },
        prevStep() {
            window, scrollTo(0, 0)
            if (this.active < 4) {
                this.active--
            } else {
                this.active -= 2
            }
            if (this.currentStep > 1) {
                this.currentStep -= 1;
            }
        },
        submit() {
            const data = {
                contactPerson: this.ruleForm.person,
                title: this.ruleForm.title,
                email: this.ruleForm.email,
                phone: this.ruleForm.phone,
                companyName: this.ruleForm.name,
                companyTaxId: this.ruleForm.taxid,
                companyAddress: this.ruleForm.add,
                website: this.ruleForm.web,
                numberOfSalesRepresentatives: this.ruleForm.repNum,
                exhibitsAttended: this.ruleForm.SHandEX,
                country: this.form1.country,
                region: this.form1.region,
                majorCity: this.form1.city,
                onlineStore: this.form2.store1,
                bricksAndMortarStoreQty: this.form2.store2,
                wholesalerCustomerQty: this.form2.WCqty,
                retailer: this.form2.ssps,
                groomingStore: this.form2.cgis,
                otherInfo: this.form2.info,
                brandsDistributed: this.form2.distributed,
                isSellThirdPartyPortal: this.form2.portals,
                marketingPlan: this.form3.plan
            };
            axios
                .post('/api/mall/member/registerAccount', data)
                .then(res => {
                    if (res.data.code === 200) {
                        this.nextStep()
                        this.$message({
                            message: 'Submitted successfully! Please wait for email reply',
                            showClose: true,
                            type: 'success',
                        });
                        this.$router.push({ path: "/finishedregister" });
                    }
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }
    }
}
</script>
<style scoped>
.wrapper {
    width: 1200px;
    margin: 50px auto;
}

.title {
    display: flex;
    justify-content: center;
}

.titles {
    font-size: 20px;
    color: #000;
}

.tit {
    font-size: 20px;
    font-weight: bold;
}

::v-deep .el-form .el-form-item__label {
    padding: 0;
}

::v-deep .el-step__head.is-success {
    color: #CA141D;
    border-color: #CA141D;
}

::v-deep .el-step__title.is-success {
    color: #CA141D;
}

::v-deep .el-step__description.is-success {
    color: #CA141D;
}

::v-deep .el-input__inner:focus {
    border-color: #CA141D;
}

::v-deep .el-textarea__inner:focus {
    border-color: #CA141D;
}

.el-button--primary:focus,
.el-button--primary:hover {
    background: #000;
    border-color: #000;
    color: #fff;
}

.el-button--primary {
    color: #FFF;
    background-color: #000;
    border-color: #000;
}
</style>